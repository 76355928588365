import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal';
import broadcast from '../context/BroadcastContext';
import { FirebaseService } from '../firebase';
import { AppleAuthorization, FacebookAuthorization, GoogleAuthorization } from '../firebase/providers';
import { actionsCart, selectorsCart } from '../redux/cart/cartReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { fetchLoginEmail, firebaseLogin, selectorsUser } from '../redux/user/userReducer';
import { usePaths } from '../components/Routes/RouterList';
import { buildLocale } from '../utils/buildLocale';
import { resetPayData } from '../utils/localStorageHelper';
import { useLessThen991 } from '../utils/mediaQuery';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { useNavigate } from 'react-router';
import SystemMessage from '../components/Modals/SystemMessage';

const LoginPage = () => {
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const path = usePaths();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [password, setPassword] = useState('');
  const userError = useSelector(selectorsUser.getError);
  const errorInfo = useSelector(selectorsUser.getErrorInfo);
  const items: any = useSelector(selectorsCart.getCartProducts);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const clientEmail = searchParams.get('email');
  const redirectFrom = searchParams.get('from');
  const [redirectLinkAfterLogin, setRedirectLinkAfterLogin] = useState('');

  const REDIRECT_FROM_RECOVERY = 'recovery';

  const emailRef = useRef(null as HTMLInputElement | null);
  const passRef = useRef(null as HTMLInputElement | null);

  useEffect(() => {
    if (redirectFrom !== REDIRECT_FROM_RECOVERY) {
      return;
    }
    dispatch(
      actionsOther.toggleSystemMessage({
        isOpen: true,
        type: 'success',
        message: buildLocale(currentTranslate, 'emailToRecoverPasswordSent'),
        duration: 7000,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectFrom]);

  useEffect(() => {
    const redirectLink = location.state?.from;
    if (redirectLink?.length) {
      setRedirectLinkAfterLogin(redirectLink);
    }

    dispatch(actionsFilters.clearAllSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorInfo) {
      firebaseAuth('google');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorInfo]);

  const isUserAuth = useSelector(selectorsUser.isAuth);

  useEffect(() => {
    if (isUserAuth) {
      navigate(path.profile('data'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuth]);

  useEffect(() => {
    if (!clientEmail?.length) {
      return;
    }
    if (emailRef.current) {
      emailRef.current.value = clientEmail;
    }
    if (passRef.current) {
      passRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmail]);

  const onSubmit = async () => {
    const res: any = await dispatch(fetchLoginEmail(email, password));
    if (res.success) {
      resetPayData();
      if (items && items.length > 0 && !isLess991) {
        navigate(path['cart-list']);
        return;
      }
      navigate(redirectLinkAfterLogin?.length ? redirectLinkAfterLogin : path.home);
      if (items && items.length > 0 && isLess991) {
        dispatch(actionsCart.toggleCart(true));
      }
    }
  };

  const firebaseAuth = async (providerKey: string) => {
    let authService: FirebaseService | null;

    switch (providerKey) {
      case 'google':
        authService = new FirebaseService(new GoogleAuthorization());
        break;

      case 'facebook':
        authService = new FirebaseService(new FacebookAuthorization());
        break;

      case 'apple':
        authService = new FirebaseService(new AppleAuthorization());
        break;

      default:
        authService = null;
        break;
    }

    if (authService) {
      const token = await authService.signin();
      const response: any = await dispatch(firebaseLogin(token));

      if (response.success) {
        broadcast.broadcastLogin(response.data);
        resetPayData();
        if (items && items.length > 0 && !isLess991) {
          navigate(path['cart-list']);
          return;
        }
        navigate(redirectLinkAfterLogin?.length ? redirectLinkAfterLogin : path.home);
        if (items && items.length > 0 && isLess991) {
          dispatch(actionsCart.toggleCart(true));
        }
      }
    }
  };

  const closeLoginPopup = () => {
    dispatch(actionsOther.setOpenLoginData({ isOpenLogin: false, redirectLink: '' }));
  };

  return (
    <section
      className="section-register"
      onClick={(e) => {
        if (isOpen) {
          // close the ForgotPasswordModal
          setIsOpen(false);
        }

        closeLoginPopup();

        e.stopPropagation();
      }}>
      {isLess991 && <SystemMessage />}
      <span className="link-back" onClick={() => navigate(-1)}>
        {buildLocale(currentTranslate, 'loginGoBack')}
      </span>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
            <p className="login-title">{buildLocale(currentTranslate, 'btnTextLogin')}</p>
            <div className="login-mobile">
              <ul className="login-list">
                <li>
                  <div className="login-social login-apple">
                    <span className="icon">
                      <svg
                        data-test="IconApple"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="22"
                        viewBox="0 0 18 22">
                        <path
                          fill="#FFFFFF"
                          fillRule="evenodd"
                          d="M18 16.141c-.49 1.145-.727 1.654-1.36 2.668-.883 1.413-2.13 3.174-3.674 3.187-1.37.014-1.723-.938-3.585-.926-1.86.01-2.25.943-3.621.93-1.544-.014-2.724-1.604-3.608-3.018-2.47-3.95-2.73-8.587-1.206-11.054 1.084-1.751 2.792-2.775 4.4-2.775 1.634 0 2.663.941 4.016.941 1.312 0 2.111-.944 4.003-.944 1.431 0 2.946.819 4.025 2.231-3.537 2.037-2.963 7.34.61 8.76zm-6.068-12.57c.686-.926 1.208-2.235 1.02-3.571-1.124.08-2.438.832-3.204 1.81-.697.885-1.271 2.203-1.048 3.484 1.229.04 2.496-.73 3.232-1.723z"></path>
                      </svg>
                    </span>
                    <span onClick={() => firebaseAuth('apple')}>{buildLocale(currentTranslate, 'loginWithApple')}</span>
                  </div>
                </li>
                <li>
                  <div className="login-social login-google">
                    <span className="icon">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <g>
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                      </svg>
                    </span>
                    <span onClick={() => firebaseAuth('google')}>
                      {buildLocale(currentTranslate, 'loginWithGoogle')}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="login-social login-facebook">
                    <span className="icon">
                      <svg
                        data-test="IconFacebook"
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="23"
                        viewBox="0 0 11 23">
                        <path
                          fill="#FFFFFF"
                          fillRule="evenodd"
                          d="M7.315 23H2.437V11.499H0V7.536h2.437v-2.38C2.437 1.923 3.813 0 7.723 0h3.255v3.964H8.944c-1.523 0-1.623.554-1.623 1.588l-.007 1.984H11l-.431 3.963H7.314V23z"></path>
                      </svg>
                    </span>
                    <span onClick={() => firebaseAuth('facebook')}>
                      {buildLocale(currentTranslate, 'loginWithFacebook')}
                    </span>
                  </div>
                </li>
                <li className="mobile">
                  <div className="login-info">
                    <p className="register">
                      {buildLocale(currentTranslate, 'loginNoAcc')}{' '}
                      <a href={path.registration}>{buildLocale(currentTranslate, 'loginToRegistrationLink')}</a>
                    </p>
                    {buildLocale(currentTranslate, 'loginFooter1')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'loginFooter2')}</Link>{' '}
                    {buildLocale(currentTranslate, 'loginFooter3')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'loginFooter4')}</Link>
                  </div>
                </li>
              </ul>
              <p className="login-sep">
                <span>{buildLocale(currentTranslate, 'textOr')}</span>
              </p>
              <div className="login-form">
                <div className="form-item">
                  <input
                    ref={emailRef}
                    type="email"
                    className="form-dark"
                    placeholder={buildLocale(currentTranslate, 'tIEmailPlaceholder')}
                    onChange={(value) => setEmail(value.target.value)}
                  />
                </div>
                <div className="form-item form-item_password">
                  <input
                    ref={passRef}
                    type={isShowPass ? 'text' : 'password'}
                    className="form-dark"
                    placeholder={buildLocale(currentTranslate, 'tIPasswordPlaceholder')}
                    onChange={(value) => setPassword(value.target.value)}
                  />
                  {userError && <span style={{ color: 'red' }}>{userError}</span>}
                  <span
                    className={isShowPass ? 'btn-hide-password' : 'btn-show-password'}
                    onClick={() => setIsShowPass(!isShowPass)}></span>
                  <Link
                    to={path.forgot_pass}
                    className="forgot-password"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}>
                    {buildLocale(currentTranslate, 'authForgetPassword')}
                  </Link>
                </div>
                <button className="btn btn-submit" onClick={onSubmit}>
                  {buildLocale(currentTranslate, 'btnSubmit')}
                </button>
              </div>
              <div className="login-bottom">
                <Link to={path.registration}>{buildLocale(currentTranslate, 'signUp')}</Link>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    isLess991 ? navigate(path.forgot_pass) : setIsOpen(true);
                  }}>
                  {buildLocale(currentTranslate, 'authForgetPassword')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && !isLess991 && <ForgotPasswordModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </section>
  );
};

export default LoginPage;
