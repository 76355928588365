import { CreatorReducer } from '../base/base';
import { ICategoryActions, ICategoryState } from './categoryTypes';
import service from '../../services/service';
import { RootState } from '../reducer';
import { IFetchCategory, ITreeCategory, PageType } from '../../typings/ICategory';
import { selectorsDelivery } from '../delivery/deliveryReducer';
import { createSelector } from 'reselect';

const init: ICategoryState = {
  data: [],
  error: null,
  isLoading: false,
  rootsCategory: [],
  treeCategories: {},
  categoriesRoute: [{ id: null, title: '', type: PageType.Main }],
  categories: [],
};

const creator = new CreatorReducer<ICategoryActions, ICategoryState>('category');
creator.addAction('setCategories', (state, action) => {
  return { ...state, categories: action.payload };
});
creator.addAction('setDataItem', (state, action) => {
  return { ...state, ...action.payload };
});
creator.addAction('updateCategoriesRoute', (state, action) => {
  return { ...state, categoriesRoute: action.payload };
});
creator.addAction('transformCategories', (state, action) => {
  const transformData = transformCategories(action.payload || state.data);
  return { ...state, rootsCategory: transformData.rootsCategory, treeCategories: transformData.treeCategories };
});
creator.addAction('clearCategories', (state, action) => {
  return { ...state, categories: [], data: [] };
});
creator.addAction('clearTransformCategories', (state, action) => {
  return { ...state, rootsCategory: [], treeCategories: {} };
});
creator.addAction<IFetchCategory>('updateCategory', (state, action) => {
  const findIndex = state.data.findIndex((c) => c.id === action.payload.id);
  if (findIndex !== -1) {
    state.data[findIndex].seoText = action.payload.seoText;
    state.data[findIndex].seoTexts = action.payload.seoTexts;
  }
  const c = state.treeCategories[action.payload.id];
  if (c) {
    c.seoText = action.payload.seoText;
    c.seoTexts = action.payload.seoTexts;
  }
  return { ...state };
});
creator.addAction('clearSeoTexts', (state, action) => {
  const data = state.data.map((c) => {
    return {
      ...c,
      seoText: null,
      seoTexts: null,
    };
  });
  return { ...state, data };
});

const actionCategory = creator.createActions();

const thunkGetTreeCategories = ({ store, locale }: any) => {
  return store.dispatch(serviceGetCustomCategories(locale));
};

const serviceGetCustomCategories = (locale?: any) => async (dispatch: any, getStore: () => RootState) => {
  const store = getStore();
  if (store.category.rootsCategory.length > 0) return {};
  dispatch(actionCategory.setLoading(true));
  const isInCourierArea = selectorsDelivery.isInCourierArea(store);
  const res = await service.getCustomCategories(isInCourierArea, locale);
  const categories = await service.getCategories(locale);

  dispatch(actionCategory.setLoading(false));
  dispatch(actionCategory.setData([...getStore().category.data, ...res]));
  dispatch(actionCategory.setData([...getStore().category.data, ...categories]));
  dispatch(actionCategory.setCategories([...categories]));
  const result = res.concat(categories);
  if (result?.length && Array.isArray(result)) {
    dispatch(
      actionCategory.transformCategories(
        result.map((c) => {
          return { ...c };
        }),
      ),
    );
  }

  return result;
};

const transformCategories = (categories: IFetchCategory[]) => {
  const rootsCategory = categories.filter((c) => c.parent === null).sort((a, b) => a.ord - b.ord);
  const result: ITreeCategory = {};
  categories.forEach((c) => {
    result[c.id] = c;
  });

  categories.forEach((c) => {
    if (c.parent) {
      const category = result[c.parent.id];
      if (category) {
        if (category?.children) {
          category.children.push(c);
        } else {
          category.children = [c];
        }
      }
    }
  });

  categories.forEach((c) => {
    const category = result[c.id];
    if (category?.children) {
      category.children.sort((a, b) => a.ord - b.ord);
    }
  });

  return {
    rootsCategory,
    treeCategories: result,
    mapRootsCategory: rootsCategory.map((r) => result[r.id]),
    categories: categories.filter((c) => c.parent !== null),
  };
};

const selectTreeCategories = (state: RootState) => state.category.treeCategories;
const selectRootCategory = (state: RootState) => state.category.rootsCategory;

const getRootCategories = createSelector(
  [selectRootCategory, selectTreeCategories],
  (rootsCategory, treeCategories) => {
    return rootsCategory.map((r) => treeCategories[r.id]);
  },
);

const getVisibleRootCategories = createSelector(
  [selectRootCategory, selectTreeCategories],
  (rootsCategory, treeCategories) => {
    return rootsCategory.map((r) => treeCategories[r.id]).filter((rootCategory) => rootCategory.visibility === true);
  },
);

const getChainsCategories = (id: any) =>
  createSelector([selectTreeCategories], (treeCategories) => {
    if (!id) {
      return [];
    }
    if (!treeCategories[id]) {
      return [];
    }
    const res = [treeCategories[id]];
    let parent = res[0].parent;

    while (parent !== null && parent !== undefined) {
      const category = treeCategories[parent.id];
      if (!category) {
        return [];
      }
      res.unshift(category);
      parent = category.parent;
    }

    return res;
  });

const selectorsCategory = {
  getCategories: selectTreeCategories,
  getMainCategories: (state: RootState) => state.category.categories,
  isRestCategory: (id: any) => (state: RootState) => {
    const root: any = selectorsCategory.getRootCategory(id)(state);
    const index = selectorsCategory.getRootCategories(state).findIndex((c) => c?.id === root?.id);
    return index === 0;
  },
  getRootCategories,
  getVisibleRootCategories,
  getAllRestCategories: (state: RootState) => {
    const category = selectorsCategory.getRootCategories(state)[0];
    const result: {
      [id: string]: {
        id: number;
        name: string;
        ord: number;
      };
    } = {};

    if (!category?.children) {
      return result;
    }
    category.children.forEach((c) => {
      result[c.id] = {
        id: c.id,
        name: c.name,
        ord: c.ord || 0,
      };
      const res = selectorsCategory.getDeepCategoriesById(c.id)(state);

      res.forEach((r) => {
        result[r.id] = {
          id: c.id,
          name: c.name,
          ord: c.ord || 0,
        };
      });
    });

    return result;
  },
  getCategoriesById: (id: any) => (state: RootState) => state.category.treeCategories[id],
  getCategoriesChildrenById: (id: any) => (state: RootState) => state.category.treeCategories[id]?.children,
  getDeepCategoriesById: (id: any) => (state: RootState) => {
    if (!id) {
      return [];
    }
    const root = selectorsCategory.getCategoriesById(id)(state);
    let result: IFetchCategory[] = [];

    const getChildren = (category: IFetchCategory) => {
      if (category?.children) {
        result = [...result, ...category.children];
        category.children.forEach((c) => getChildren(c));
      }
    };
    getChildren(root);
    return result;
  },
  getRootCategory: (id: any) => (state: RootState) => {
    let category = state.category.treeCategories[id];
    if (!category) {
      return '';
    }
    if (category) {
      let parent = category.parent;
      if (parent) {
        while (parent) {
          category = state.category.treeCategories[parent.id];
          parent = category?.parent;
        }
      }

      return category;
    } else {
      return;
    }
  },
  getChainsCategories,
  getSelfIdOrChildrenIds: (id: any) => (state: RootState) => {
    const category = state.category.treeCategories[id];
    const roots = selectorsCategory.getRootCategories(state);
    if (!category) {
      return;
    }

    if (category.parent) {
      if (roots.some((r) => r.id === category.parent!.id)) {
        return category.id;
      }
    }
    if (category?.children) {
      const res = category.children.map((c) => c.id);
      res.push(id);
      return res;
    }
    return category.id;
  },
};

export { actionCategory, serviceGetCustomCategories, transformCategories, selectorsCategory, thunkGetTreeCategories };
export default creator.createReducerFetch(init);
