import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { productDefaultImage } from '../../../constants/productDefaultImage';
import OtherImage from '../../ImagesComponent/OtherImage';
import { buildLocale } from '../../../utils/buildLocale';
import config from '../../../config';
import { Link } from 'react-router-dom';
import { usePaths } from '../../Routes/RouterList';
import { ISubscriptionItem } from '../../../typings/ISubscriptionItem';
import bellFillIcon from '../../../assets/images/bell-fill.svg';
import service from '../../../services/service';
import { refreshUser } from '../../../redux/user/userReducer';

interface ISubscriptionItemProps {
  item: ISubscriptionItem;
  reloadSubscription: () => void;
}

const SubscriptionItem = (props: ISubscriptionItemProps) => {
  const { item, reloadSubscription } = props;
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch = useDispatch<any>();

  const tryToUnsubscribe = () => {
    service.unsubscribeClientFromProduct(item.id).then(() => {
      dispatch(refreshUser);
      reloadSubscription();
    });
  };

  const getProductImage = (item: ISubscriptionItem) => {
    const cdnImages = item.productOption.product.cdnImages;
    return cdnImages.at(0) ? cdnImages.at(0) : productDefaultImage;
  };

  const image = getProductImage(item);

  return (
    <div className="profile-subscription_history-item">
      <div className="inner">
        <figure className="pic">
          <OtherImage
            src={image?.variants['250x150']}
            title={item.productOption.product.title}
            width="60"
            height="60"
          />
        </figure>
        <div className="info">
          <div className="info-left">
            <Link
              target="_blank"
              className="info-name"
              to={`${config.canLink}${paths.product(item.productOption.product.id)}`}>
              {item.productOption.product.title}
            </Link>
            <div className="subscription-message">
              <img src={bellFillIcon} alt="" width="16" height="16" />
              <span className="btn-more" onClick={tryToUnsubscribe}>
                {buildLocale(currentTranslate, 'productAvailabilityDeactivateSubscription')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionItem;
