import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IFetchCategory } from '../../typings/ICategory';
import TabsList from '../common/TabList';
import ProductLoader from '../Loaders/ProductLoader';
import OwnCategoriesComponent from '../common/CustomSlider';
import GoBackBtn from '../CategoriesMobile/GoBackBtn';
import FilterComponent from '../common/Filters/FilterComponent';
import { FILTER_TYPE } from '../../typings/IFilters';
import Products from '../Products/Products';
import { selectorsFilters } from '../../redux/filters/filtersReducer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useNavigate, useNavigationType } from 'react-router';
import isLocationForFirstLoadedPage from '../../utils/isLocationForFirstLoadedPage';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { selectorsOther } from '../../redux/other/otherReducer';
import { useLessThen991 } from '../../utils/mediaQuery';

interface ICategoryFirstLevelProps {
  currentCategory: IFetchCategory;
  setIsLoading: any;
  query: any;
  chainsCategory: IFetchCategory[];
  startPage: number;
}

const CategoryFirstLevel = (props: ICategoryFirstLevelProps) => {
  const { currentCategory, chainsCategory, query, startPage } = props;
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const [limit, setLimit] = useState(4);
  const [isOrderByHighPrice, setIsOrderByHighPrice] = useState<undefined | boolean>(undefined);
  const [isHasMore, setIsHasMore] = useState(true);
  const [shownCategories, setShownCategories] = useState<Array<any>>(
    currentCategory?.children?.length ? currentCategory.children : [],
  );
  const [productsTrigger, setProductsTrigger] = useState(false);

  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useSelector(selectorsLocale.getTranslate);

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={translate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setProductsTrigger(!productsTrigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  useEffect(() => {
    if (currentCategory?.children?.length) {
      setShownCategories(currentCategory.children);
    }
  }, [currentCategory]);

  const changeLimit = () => {
    if (!shownCategories?.length) {
      return;
    }
    if (shownCategories.length >= currentCategory?.children?.length || 0) {
      setIsHasMore(false);
    } else {
      const newLimit = limit + 1;
      setLimit(newLimit);
      setShownCategories(currentCategory.children.slice(0, newLimit));
    }
  };

  const applyFilters = () => {
    setProductsTrigger(!productsTrigger);
  };

  const setSort = (sortValue: boolean | undefined) => {
    setIsOrderByHighPrice(sortValue);
    applyFilters();
  };

  return (
    <section className="category-first">
      {backBtn}
      <div className="container">
        {currentCategory?.children && currentCategory?.children?.length && (
          <TabsList items={currentCategory.children} />
        )}
        {chainsCategory.length !== 3 && (
          <FilterComponent
            id={currentCategory.id}
            type={FILTER_TYPE.OWNCATEGORY}
            applyFilters={applyFilters}
            sortProducts={setSort}
          />
        )}
      </div>
      {shownCategories.length > 0 && chainsCategory?.length !== 3 ? (
        <InfiniteScroll
          dataLength={shownCategories.length}
          style={{ overflowX: 'hidden' }}
          next={() => changeLimit()}
          hasMore={isHasMore}
          loader={<ProductLoader />}>
          {shownCategories.map((category: any) => {
            return (
              <OwnCategoriesComponent
                key={category.id}
                id={category.id}
                name={category.name}
                parentId={currentCategory.id}
                setLimit={changeLimit}
                isOrderByHighPrice={isOrderByHighPrice}
              />
            );
          })}
        </InfiniteScroll>
      ) : (
        <Products
          className="col-12 catalog-right"
          query={query}
          seoText={startPage === 1 ? currentCategory.seoText! : undefined}
          hideBackBtn={true}
        />
      )}
    </section>
  );
};

export default CategoryFirstLevel;
