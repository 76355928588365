import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ItemInput from '../components/Forms/Components/ItemInput';
import { actionsUser } from '../redux/user/userReducer';
import { usePaths } from '../components/Routes/RouterList';
import service from '../services/service';
import validation from '../utils/validation';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';

import { FirebaseService } from '../firebase';
import { GoogleAuthorization, FacebookAuthorization, AppleAuthorization } from '../firebase/providers';
import { firebaseLogin } from '../redux/user/userReducer';
import config from '../config';
import { buildLocale } from '../utils/buildLocale';
import { selectorsLocale } from '../redux/locale/localeReducer';
import ShowError from '../components/Forms/Components/ShowError';
import { actionsCart, selectorsCart } from '../redux/cart/cartReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import { getLocaleStorageItem, resetPayData } from '../utils/localStorageHelper';
import { isMetroShop } from '../constants/shops';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { draftId } from '../constants/payData';
import { useNavigate } from 'react-router';
import { selectorsOther } from '../redux/other/otherReducer';

const RegistrationPage = (props: any) => {
  const navigate = useNavigate();
  const path = usePaths();
  const dispatch = useDispatch<any>();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const items: any = useSelector(selectorsCart.getCartProducts);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const isMetro = isMetroShop(config.shopId);
  const {
    handleSubmit,
    formState: { errors },
    register,
    setFocus,
    setValue,
    watch,
  } = useForm<any>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });
  const phoneRegister = register('phone', validation.phone);
  const password = useRef({});
  password.current = watch('password', '');

  const [errorMessage, setErrorMessage] = useState('');
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const reRef = useRef<ReCAPTCHA>();

  useEffect(() => {
    dispatch(actionsFilters.clearAllSelected());
    if (setFocus) {
      setFocus('firstName');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!zipCode) {
      return;
    }
    setValue('zipCode', zipCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const handleClientCartAfterRegistration = () => {
    service.getCart(zipCode).then((value) => {
      const cartItems = value.items;
      if (cartItems && cartItems.length > 0 && window?.screen?.width > 990) {
        navigate(path['cart-list']);
        return;
      }
      navigate(path.home);
      if (cartItems && cartItems.length > 0 && window?.screen?.width < 991) {
        dispatch(actionsCart.toggleCart(true));
      }
    });
  };
  const tryToRegisterClient = async () => {
    const queryParams = window && new URLSearchParams(window.location.search);
    const clientEmail = queryParams.get('email');
    const clientCode = queryParams.get('code');
    if (!clientEmail || !clientCode) {
      return;
    }
    dispatch(actionsUser.setLoading(true));
    const res = await service.confirmRegistration({
      email: clientEmail,
      code: clientCode,
      draftOrderId: getLocaleStorageItem(draftId),
    });
    if (res.success) {
      dispatch(actionsUser.setData(res.data));
      dispatch(actionsUser.setLoading(false));
      handleClientCartAfterRegistration();
      return;
    }
    if (res.codeInfo) {
      dispatch(actionsUser.setErrorInfo(res.codeInfo));
    }
    dispatch(actionsUser.setError(res.data));
    navigate(`${path['finish_registration']}?email=${clientEmail}`);
  };

  useEffect(() => {
    tryToRegisterClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    if (values.password !== values.confirmedPassword) {
      setErrorMessage('Passwörter haben Co-Kammern');
      return;
    }
    setDisableSubmitBtn(true);

    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    service
      .registration(values, token)
      .then((res: any) => {
        if (res.success) {
          navigate(`${path.finish_registration}?email=${values.email}`);
        } else {
          setErrorMessage(res.data);
        }
      })
      .catch((e: any) => {
        setErrorMessage(e);
        console.error(e);
      })
      .finally(() => {
        setDisableSubmitBtn(false);
      });
  };

  const firebaseAuth = async (providerKey: string) => {
    let authService: FirebaseService | null;

    switch (providerKey) {
      case 'google':
        authService = new FirebaseService(new GoogleAuthorization());
        break;

      case 'facebook':
        authService = new FirebaseService(new FacebookAuthorization());
        break;

      case 'apple':
        authService = new FirebaseService(new AppleAuthorization());
        break;

      default:
        authService = null;
        break;
    }

    if (authService) {
      const token = await authService.signin();
      await dispatch(firebaseLogin(token));
      if (token) {
        resetPayData();
        if (items && items.length > 0 && !isLess991) {
          navigate(path['cart-list']);
          return;
        }
        navigate(path.home);
        if (items && items.length > 0 && isLess991) {
          dispatch(actionsCart.toggleCart(true));
        }
      }
    }
  };

  return (
    <section className="section-register">
      <span className="link-back" onClick={() => navigate(-1)}>
        {buildLocale(currentTranslate, 'loginGoBack')}
      </span>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{buildLocale(currentTranslate, 'registerTitle')}</h1>
            <div className="row register-row">
              <div className="col-lg-6 register-left">
                <div className="register-social">
                  <ul className="login-list">
                    <li>
                      <div className="login-social login-apple">
                        <span className="icon">
                          <svg
                            data-test="IconApple"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="22"
                            viewBox="0 0 18 22">
                            <path
                              fill="#FFFFFF"
                              fillRule="evenodd"
                              d="M18 16.141c-.49 1.145-.727 1.654-1.36 2.668-.883 1.413-2.13 3.174-3.674 3.187-1.37.014-1.723-.938-3.585-.926-1.86.01-2.25.943-3.621.93-1.544-.014-2.724-1.604-3.608-3.018-2.47-3.95-2.73-8.587-1.206-11.054 1.084-1.751 2.792-2.775 4.4-2.775 1.634 0 2.663.941 4.016.941 1.312 0 2.111-.944 4.003-.944 1.431 0 2.946.819 4.025 2.231-3.537 2.037-2.963 7.34.61 8.76zm-6.068-12.57c.686-.926 1.208-2.235 1.02-3.571-1.124.08-2.438.832-3.204 1.81-.697.885-1.271 2.203-1.048 3.484 1.229.04 2.496-.73 3.232-1.723z"></path>
                          </svg>
                        </span>
                        <span onClick={() => firebaseAuth('apple')}>
                          {buildLocale(currentTranslate, 'registrWithApple')}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="login-social login-google">
                        <span className="icon">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                            <g>
                              <path
                                fill="#EA4335"
                                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                              <path
                                fill="#4285F4"
                                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                              <path
                                fill="#FBBC05"
                                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                              <path
                                fill="#34A853"
                                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                              <path fill="none" d="M0 0h48v48H0z"></path>
                            </g>
                          </svg>
                        </span>
                        <span onClick={() => firebaseAuth('google')}>
                          {buildLocale(currentTranslate, 'registrWithGoogle')}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="login-social login-facebook">
                        <span className="icon">
                          <svg
                            data-test="IconFacebook"
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="23"
                            viewBox="0 0 11 23">
                            <path
                              fill="#FFFFFF"
                              fillRule="evenodd"
                              d="M7.315 23H2.437V11.499H0V7.536h2.437v-2.38C2.437 1.923 3.813 0 7.723 0h3.255v3.964H8.944c-1.523 0-1.623.554-1.623 1.588l-.007 1.984H11l-.431 3.963H7.314V23z"></path>
                          </svg>
                        </span>
                        <span onClick={() => firebaseAuth('facebook')}>
                          {buildLocale(currentTranslate, 'registrWithFacebook')}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <p className="register-login_text">
                    {buildLocale(currentTranslate, 'registerHasAcc')}{' '}
                    <a href={path.login} className="link-enter">
                      {buildLocale(currentTranslate, 'registerLoginLink')}
                    </a>
                  </p>
                  <div className="login-info">
                    <p className="register">
                      {buildLocale(currentTranslate, 'registerHasAccMobile')}{' '}
                      <a href={path.login}>{buildLocale(currentTranslate, 'registerLoginLink')}</a>
                    </p>
                    {buildLocale(currentTranslate, 'registerFooter1')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'registerFooter2')}</Link>{' '}
                    {buildLocale(currentTranslate, 'registerFooter3')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'registerFooter4')}</Link>
                  </div>
                </div>

                <span className="register-sep">
                  <span>{buildLocale(currentTranslate, 'textOr')}</span>
                </span>
              </div>
              <div className="col-lg-6 register-right">
                <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'firstNamePlaceholder')}
                      type="text"
                      {...register('firstName', validation.required)}
                    />
                  </div>
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'lastNamePlaceholder')}
                      type="text"
                      {...register('lastName', validation.required)}
                    />
                  </div>
                  {isMetro && (
                    <div className="form-item registration-item">
                      <ItemInput
                        errors={errors}
                        name={'company'}
                        className="form-dark"
                        placeholder={buildLocale(currentTranslate, 'tICompanyPlaceholder')}
                        type="text"
                      />
                    </div>
                  )}
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'emailPlaceholder')}
                      type="email"
                      {...register('email', validation.email)}
                    />
                  </div>
                  <div className="form-item registration-item-note">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'passPlaceholder')}
                      type={showPass1 ? 'text' : 'password'}
                      {...register('password', validation.pass)}
                    />
                    <p className="form-note">{buildLocale(currentTranslate, 'passwordInputHint')}</p>
                    <span
                      className={showPass1 ? 'btn-hide-password' : 'btn-show-password'}
                      onClick={() => setShowPass1(!showPass1)}></span>
                  </div>
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'confirmPassPlaceholder')}
                      type={showPass2 ? 'text' : 'password'}
                      {...register('confirmedPassword', {
                        validate: (value) => value === password.current || 'Your passwords do no match',
                      })}
                    />
                    <span
                      className={showPass2 ? 'btn-hide-password' : 'btn-show-password'}
                      onClick={() => setShowPass2(!showPass2)}></span>
                  </div>
                  <div className="form-item form-item_note">
                    <PhoneInput
                      inputProps={{
                        errors: errors,
                        name: phoneRegister.name,
                        ref: phoneRegister.ref,
                      }}
                      enableSearch
                      placeholder={buildLocale(currentTranslate, 'phonePlaceholder')}
                      country={'de'}
                      onChange={(value, _, e) => phoneRegister.onChange(e)}
                      onBlur={(e) => phoneRegister.onBlur(e)}
                    />
                    {errors && <ShowError errors={errors} name={phoneRegister.name} />}
                    <p className="form-note">{buildLocale(currentTranslate, 'phoneInputMessage')}</p>
                  </div>
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'regionPlaceholder')}
                      type="text"
                      {...register('region', validation.required)}
                    />
                  </div>
                  <div className="form-item registration-item">
                    <ItemInput
                      errors={errors}
                      className="form-dark"
                      placeholder={buildLocale(currentTranslate, 'zipPlaceholder')}
                      type="text"
                      {...register('zipCode', validation.zipCode)}
                    />
                  </div>
                  <p className="form-note">
                    {buildLocale(currentTranslate, 'registerDesc1')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'registerDesc2')}</Link>{' '}
                    {buildLocale(currentTranslate, 'registerDesc3')}{' '}
                    <Link to={path.confidentiality}>{buildLocale(currentTranslate, 'registerDesc4')} </Link>
                    {buildLocale(currentTranslate, 'registerDesc5')}
                  </p>
                  {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                  <button type="submit" className="btn" disabled={disableSubmitBtn}>
                    {buildLocale(currentTranslate, 'registerConfirmBtn')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA sitekey={config.recaptchaPublicSiteKey} size="invisible" ref={reRef} className="recaptcha-registr" />
    </section>
  );
};

export default RegistrationPage;
