import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleInput from '../components/Forms/Components/SimpleInput';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import validation from '../utils/validation';
import { actionsOther } from '../redux/other/otherReducer';
import service from '../services/service';
import { usePaths } from '../components/Routes/RouterList';
import { useNavigate } from 'react-router';

interface RestorePassword {
  password: string;
  confirmedPassword: string;
}

interface Location {
  search: string;
}

const urlQueryParser = (location: Location): { [key: string]: string } => {
  try {
    if (typeof location.search !== 'string') {
      console.info(`urlQueryParser: location.search has invalid type`);
      return {};
    }

    const queryArray = location.search.slice(1, location.search.length).split('&');

    return queryArray.reduce((prev, curr) => {
      const [key, value] = curr.split('=');
      prev = { ...prev, [key]: value };
      return prev;
    }, {});
  } catch (error) {
    console.error(error);
    return {};
  }
};

const RestorePasswordPage = () => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const path = usePaths();
  const navigate = useNavigate();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [confirmed, setConfirmed] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const [user, setUser] = useState({ email: '', code: '' });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
  } = useForm<RestorePassword>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async ({ password, confirmedPassword }: RestorePassword) => {
    const { email, code } = user;
    const result = await service.restorePassword({ email, code, password, confirmedPassword });

    if (!result.success) {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'error',
          message: result.data,
          duration: 7000,
        }),
      );
    }

    if (result.success) {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'success',
          message: result.data || buildLocale(currentTranslate, 'passwordRestoredSuccessfully'),
          duration: 7000,
        }),
      );
      navigate(`${path.login}?email=${email}`);
    }
  };

  useEffect(() => {
    const { email, code } = urlQueryParser(location as Location);

    if (email === null || typeof email !== 'string') {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'error',
          message: buildLocale(currentTranslate, 'validationRestorePasswordAccess'),
        }),
      );
      setConfirmed(false);
    }

    if (code === null || typeof code !== 'string') {
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'error',
          message: buildLocale(currentTranslate, 'validationRestorePasswordAccess'),
        }),
      );
      setConfirmed(false);
    }

    setUser({ email, code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="section-register">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{buildLocale(currentTranslate, 'profileRestorePassword')}</h1>
          </div>
          <div className="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
            <form className="restore-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-item">
                <label className="label">{buildLocale(currentTranslate, 'tIPasswordNewLabelExtended')}</label>
                <SimpleInput
                  className="form-dark"
                  errors={errors}
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', validation.pass)}
                  placeholder={`${buildLocale(currentTranslate, 'tIPasswordNewPlaceholder')}*`}
                />

                <span
                  className={showPassword ? 'btn-hide-password' : 'btn-show-password'}
                  onClick={() => setShowPassword((state) => !state)}></span>
              </div>
              <div className="form-item">
                <label className="label">{buildLocale(currentTranslate, 'tIPasswordConfirmLabelExtended')}</label>

                <SimpleInput
                  className="form-dark"
                  errors={errors}
                  type={showConfirmedPassword ? 'text' : 'password'}
                  {...register('confirmedPassword', {
                    validate: (value) => value === password.current || 'Your passwords do no match',
                  })}
                  placeholder={`${buildLocale(currentTranslate, 'tIPasswordRepeatPlaceholder')}*`}
                />

                <span
                  className={showConfirmedPassword ? 'btn-hide-password' : 'btn-show-password'}
                  onClick={() => setShowConfirmedPassword((state) => !state)}></span>
              </div>

              <button type="submit" className="btn" disabled={!confirmed}>
                {buildLocale(currentTranslate, 'btnRestore')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RestorePasswordPage;
