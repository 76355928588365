import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { buildLocale } from '../../../utils/buildLocale';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { IReview } from '../../../typings/IReview';
import ProductLoader from '../../Loaders/ProductLoader';
import ReviewStars from './ReviewStars';
import { useFormattingContext } from '../../../context/FormattingContext';
import ManageProductReviewWidget from './ManageProductReviewWidget';
import replyIcon from '../../../assets/images/reply-up.svg';

interface ProductReviewWidgetProps {
  reviews: IReview[];
  productId: number;
  productTitle: string;
  ratingValue: number;
  itemsCount: number;
  isLoading: boolean;
  openManagingForm: boolean;
}

const ViewProductReviewWidget = (props: ProductReviewWidgetProps) => {
  const { reviews, productId, productTitle, ratingValue, itemsCount, isLoading, openManagingForm } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [isManagingAvailable, setIsManagingAvailable] = useState(false);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
  const { longFormatDate } = useFormattingContext();

  useEffect(() => {
    if (!openManagingForm) {
      return;
    }
    const timeout = setTimeout(() => setIsManagingAvailable(true), 50);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openManagingForm]);

  const toggleManagingBlock = (e) => {
    e.preventDefault();
    setIsShowSuccessMessage(false);
    setIsManagingAvailable(!isManagingAvailable);
  };

  const onAfterSubmit = () => {
    setIsShowSuccessMessage(true);
    setIsManagingAvailable(false);
  };

  return (
    <>
      {isLoading && <ProductLoader />}
      {!isLoading && (
        <>
          <hr />
          <h3>
            {buildLocale(currentTranslate, 'commonReviews')}
            <span className="product-reviews_header_product_title">{productTitle}</span>(<span>{itemsCount}</span>)
          </h3>
          <div className="product-reviews_bottom_head">
            <div>
              <ReviewStars
                isEditable={false}
                ratingValue={ratingValue}
                additionalData={
                  <>
                    <span className="product-reviews_bottom_head_rate">
                      {Number.parseFloat(ratingValue?.toString() || '0')}
                      <span>/5</span>
                    </span>
                    <span className="product-reviews_bottom_head_rate_title">
                      {buildLocale(currentTranslate, 'averageRating')}
                    </span>
                  </>
                }
              />
            </div>
            <div className="product-reviews_bottom_head_button">
              <span className="btn-more" onClick={toggleManagingBlock}>
                {buildLocale(currentTranslate, 'writeReview')}
              </span>
            </div>
          </div>
          {isShowSuccessMessage && <p className="reviewMessage">{buildLocale(currentTranslate, 'reviewMessage')}</p>}
          <hr />
          {isManagingAvailable && (
            <>
              <ManageProductReviewWidget productId={productId} onAfterSubmit={onAfterSubmit} />
              <hr />
            </>
          )}

          {reviews?.map((review: IReview) => (
            <div className="product-reviews_bottom_body" key={review.id}>
              <div className="product-reviews_bottom_body_stars">
                <ReviewStars isEditable={false} ratingValue={review.rating} />
              </div>
              <div className="product-reviews_bottom_body_text">
                {!!review.text?.length && <p>{review.text}</p>}
                <p>
                  <span className="description">
                    {!!review.client?.firstName && (
                      <span>
                        <span className="label-title">
                          {buildLocale(currentTranslate, 'ratingVon')}
                          <span>:</span>
                        </span>
                        <span className="label-value">{review.client.firstName.split(' ').at(0)}</span>
                      </span>
                    )}
                    {!!review.client?.lastName?.length && (
                      <span className="last-name-title">{review.client.lastName}.</span>
                    )}
                  </span>

                  <span className="description">
                    <span className="label-title">
                      {buildLocale(currentTranslate, 'ratingAm')}
                      <span>:</span>
                    </span>
                    <span className="label-value">{longFormatDate(new Date(review.publicationDate))}</span>
                  </span>
                </p>
                {!!review.reply?.length && (
                  <div className="reply-box">
                    <p className="reply-text">
                      <span>
                        <img src={replyIcon} alt="" width="16" height="16" />
                      </span>
                      <span>{review.reply}</span>
                    </p>
                    <p className="reply-author">
                      <b>{buildLocale(currentTranslate, 'ratingVon')}</b>: MyBio
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ViewProductReviewWidget;
