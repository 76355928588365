import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import service from '../../services/service';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { usePaths } from '../Routes/RouterList';
import Pagination from '../common/Pagination';
import { ISubscriptionItem } from '../../typings/ISubscriptionItem';
import SubscriptionItem from './Components/SubscriptionItem';

const SubscriptionsOption = () => {
  const paths = usePaths();
  const [searchParams] = useSearchParams();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [subscriptionItems, setSubscriptionItems] = useState<ISubscriptionItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const perPage = 10;

  useEffect(() => {
    changePagination(Number(searchParams.get('page')) || 1, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptionItems = (params) => {
    setIsLoading(true);
    service
      .getClientSubscriptions(params)
      .then((res) => {
        setCount(res.data.count);
        setSubscriptionItems(res.data.items);
      })
      .catch((error) => console.error(error.message))
      .finally(() => setIsLoading(false));
  };

  const changePagination = async (page: number, length: number) => {
    const paginationParams = {
      top: length * perPage,
      skip: (page - 1) * perPage,
    };
    getSubscriptionItems(paginationParams);
  };

  return (
    <div className="tab-pane data">
      <Link to={paths.profile()} className="back-link">
        {buildLocale(currentTranslate, 'headerProfile')}
      </Link>
      <div className="profile-block profile-block_info">
        {!!subscriptionItems?.length && (
          <ul>
            {subscriptionItems.map((item: ISubscriptionItem) => (
              <li key={item.id}>
                <SubscriptionItem item={item} reloadSubscription={() => changePagination(1, 1)} />
              </li>
            ))}
          </ul>
        )}
        {!subscriptionItems?.length && <p>{buildLocale(currentTranslate, 'profileNoSubscriptions')}</p>}
      </div>
      <Pagination
        availableCount={1000}
        count={count}
        perPage={perPage}
        onCurrentPages={changePagination}
        loading={isLoading}
      />
    </div>
  );
};

export default SubscriptionsOption;
