import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import service from '../services/service';
import { generateClassName } from '../utils/generateClassName';
import ShowError from '../components/Forms/Components/ShowError';
import config from '../config';
import { usePaths } from '../components/Routes/RouterList';
import { buildLocale } from '../utils/buildLocale';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import { useNavigate } from 'react-router';

const ForgotPasswordModal = () => {
  const dispatch = useDispatch<any>();
  const reRef = useRef<ReCAPTCHA>();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const path = usePaths();
  const navigate = useNavigate();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const { isOpenLogin } = useSelector(selectorsOther.getOpenLoginData);

  const resetPasswordRequest = async () => {
    setIsLoading(true);
    if (!email.length) {
      setIsLoading(false);
      return;
    }
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    const result = await service.resetPassword({ email, token });
    if (!result.success) {
      setErrors({ email: { message: result.data } });
    }
    if (result.success) {
      // close ForgotPasswordModal
      blockBodyScroll(false);
      // close LoginPopup
      dispatch(actionsOther.setOpenLoginData({ isOpenLogin: !isOpenLogin, redirectLink: '' }));
      dispatch(
        actionsOther.toggleSystemMessage({
          isOpen: true,
          type: 'success',
          message: result.data || buildLocale(currentTranslate, 'emailToRecoverPasswordSent'),
          duration: 7000,
        }),
      );
      navigate(`${path.login}?from=recovery`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    blockBodyScroll(true);
  }, []);

  const onSubmitEmail = async (key?: string) => {
    if (key === 'Enter' || !key) {
      await resetPasswordRequest();
    }
  };

  return (
    <div className={`edit_modal info_modal`}>
      <div className="modal_add-card registration-modal" onClick={(e) => e.stopPropagation()}>
        <div className="mobile">
          <h1>{buildLocale(currentTranslate, 'loginForgetPassword')}</h1>
          <div className="forgot-content">{buildLocale(currentTranslate, 'hcText42')}</div>
          <div className="form-item">
            <input
              type="email"
              name="email"
              className={generateClassName('form-dark', {
                'error-form-border': errors ? !!errors.email : false,
              })}
              placeholder={buildLocale(currentTranslate, 'placeholderEmail')}
              onChange={(value) => setEmail(value.target.value)}
              value={email}
              onKeyDown={(e) => onSubmitEmail(e.key)}
            />
            {errors && <ShowError errors={errors} name={'email'} />}
          </div>
          <button disabled={isLoading} className="btn btn-submit" onClick={() => resetPasswordRequest()}>
            {buildLocale(currentTranslate, 'btnSend')}
          </button>
          <span
            onClick={() => {
              blockBodyScroll(false);
              navigate(-1);
            }}
            className="close-modal-pass">
            ← {buildLocale(currentTranslate, 'commonReturn')}
          </span>
        </div>
      </div>
      <ReCAPTCHA sitekey={config.recaptchaPublicSiteKey} size="invisible" ref={reRef} />
    </div>
  );
};
export default ForgotPasswordModal;
